import React from "react";
import { images } from "../../constants";
import { Zoom } from "react-awesome-reveal";
import "./Header.css";
import "../../constants/styles.css";

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <Zoom>
        <h1 className="app__header-h1">
          BIGGEST AND ASTOUNDING ROCK FIESTA 2024
        </h1>
      </Zoom>
    </div>

    {/* <div className="app__wrapper_img"> */}
    <Zoom>
      <img className="synchro__frame_1" src={images.home} alt="header_img" />
    </Zoom>
    {/* </div> */}
  </div>
);

export default Header;
