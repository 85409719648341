import { Fade } from "react-awesome-reveal";
import "./Schedule.css";

const Schedule = ({ data }) => {
  return (
    <div
      className="app_schedule app__wrapper section__padding"
      style={{ overflow: "hidden" }}
      id="schedule"
    >
      <Fade bottom>
        <h1 className="headtext__cormorant">Schedule</h1>
      </Fade>
      {data.length <= 0 ? (
        <h1 style={{ color: "white" }}>To be announced...</h1>
      ) : (
        data.map((events) => (
          <div className="schedule__block">
            <div
              className="schedule__bgImg"
              style={{ backgroundImage: `url("${events.img.url}")` }}
            ></div>

            <div className="schedule__place">
              <p className="schedule__city">{events.name}</p>
            </div>
            <div className="schedule__content">
              <h1 className="schedule__date">{events.text1}</h1>
              {events.text2 !== "TBA" && (
                <p className="schedule__result">
                  Winner: <span>{events.text2}</span>
                </p>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};
export default Schedule;
