import bg from "../assets/bg.jpg";
import synchro_logo from "../assets/synchro_logo.png";
import insta from "../assets/insta.png";
import fb from "../assets/fb.png";
import kolkata from "../assets/kolkata.jpg";
import vadodara from "../assets/vadodara.jpg";
import hyderabad from "../assets/hyderabad.jpg";
import chennai from "../assets/chennai.jpg";
import chandigarh from "../assets/chandigarh.jpg";
import delhi from "../assets/delhi.jpg";
import shillong from "../assets/shillong.jpg";
import bangalore from "../assets/bangalore.jpg";
import mumbai from "../assets/mumbai.jpg";
import imphal from "../assets/imphal.jpg";
import gallery01 from "../assets/gallery/1.jpg";
import gallery02 from "../assets/gallery/2.jpg";
import gallery03 from "../assets/gallery/3.jpg";
import gallery04 from "../assets/gallery/4.jpg";
import gallery05 from "../assets/gallery/5.jpg";
import gallery06 from "../assets/gallery/6.jpg";
import gallery07 from "../assets/gallery/7.jpg";
import gallery08 from "../assets/gallery/8.jpg";
import home from "../assets/home.jpg";
import topcat from "../assets/sponsors/topcat.jpeg";
import kingstudio from "../assets/sponsors/kingstudio.png";
import facebroke from "../assets/sponsors/facebroke.png";
import northEasternNews from "../assets/sponsors/northEasternNews.jpeg";
import TIMD from "../assets/sponsors/TIMD.jpeg";
import ace from "../assets/sponsors/ace.jpeg";
import TNEC from "../assets/sponsors/TNEC.png";
import blckcnn from "../assets/sponsors/blckcnn.jpg";
import darkVille from "../assets/sponsors/darkVille.png";
import SHP from "../assets/sponsors/SHP.jpg";
import cnl from "../assets/sponsors/cnl.png";
import rtr from "../assets/sponsors/rtr.png";
import upArrow from "../assets/up-arrow.png";
import downArrow from "../assets/down-arrow.png";
import rightArrow from "../assets/right-arrow.png";
import leftArrow from "../assets/left-arrow.png";
import journey2018 from "../assets/journey/journey2018.jpg";

const images = {
  bg,
  synchro_logo,
  insta,
  fb,
  kolkata,
  vadodara,
  hyderabad,
  mumbai,
  chennai,
  chandigarh,
  bangalore,
  delhi,
  imphal,
  shillong,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  home,
  topcat,
  kingstudio,
  facebroke,
  northEasternNews,
  TIMD,
  ace,
  TNEC,
  SHP,
  blckcnn,
  darkVille,
  cnl,
  rtr,
  upArrow,
  downArrow,
  rightArrow,
  leftArrow,
  journey2018,
};

export default images;
