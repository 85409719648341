import { useState, useEffect } from "react";
import "./Journey.css";
import { Fade } from "react-awesome-reveal";
import { data } from "../../constants";
import { images } from "../../constants";
import { getSingleDoc } from "../../config/firebaseFirestore";

const Journey = () => {
  const [journeyData, setJourneyData] = useState([]);

  const getJourneyData = async () => {
    let data = await getSingleDoc("WebContents", "Synchro23");
    if (data != null) {
      const dataArray = data.data;
      // console.log(dataArray);
      const journeyArray = [];
      for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i].flag === "journey") {
          journeyArray.push(dataArray[i]);
        }
      }
      setJourneyData(journeyArray);
    }
  };

  useEffect(() => {
    getJourneyData();
  }, []);

  const handleYearClick = (year) => {
    const newActiveID = data.journey.findIndex(
      (timestamp) => timestamp.year === year
    );
    setActiveID(newActiveID);
  };

  const handleUpClick = () => {
    if (activeID === 0) {
      setActiveID(data.journey.length - 1);
    } else {
      setActiveID(activeID - 1);
    }
  };

  const handleDownClick = () => {
    if (activeID === data.journey.length - 1) {
      setActiveID(0);
    } else {
      setActiveID(activeID + 1);
    }
  };

  const [activeID, setActiveID] = useState(0);

  return (
    <>
      <div className="app__journey app__wrapper section__padding" id="journey">
        <Fade bottom>
          <h1 className="headtext__cormorant">Journey</h1>
        </Fade>
        <div className=" app__wrapper synchro__frame_1 app__journey-content">
          <button className="col mobilearrows__journey">
            <img
              onClick={() => handleUpClick()}
              src={images.upArrow}
              alt="upArrow"
            ></img>
          </button>
          <div className="row app__journey-content_left flex__center">
            <div className="col-xl-3 col-lg-4 col-12 app__journey-content__text">
              <h1 className="headtext__cormorant">
                {data.journey[activeID].year}
              </h1>
              <p className="p__cormorant">{data.journey[activeID].content}</p>
            </div>
            <div className="col-xl-9 col-lg-8 col-12 app__journey-content__img">
              <img
                src={data.journey[activeID].img}
                alt=""
                className="images__journey"
              />
            </div>
          </div>
          <button className="col mobilearrows__journey">
            <img
              onClick={() => handleDownClick()}
              src={images.downArrow}
              alt="DownArrow"
            ></img>
          </button>
          <div className="app__journey-content_right">
            <button className="arrows__journey">
              <img
                onClick={() => handleUpClick()}
                src={images.upArrow}
                alt="upArrow"
              ></img>
            </button>
            <div className="timeline__journey">
              <Fade bottom>
                {data.journey.map((timestamp) => {
                  return (
                    <button
                      key={timestamp.id}
                      className="p__cormorant year__journey"
                      onClick={() => handleYearClick(timestamp.year)}
                    >
                      {timestamp.year}
                    </button>
                  );
                })}
              </Fade>
            </div>
            <button className="arrows__journey">
              <img
                onClick={() => handleDownClick()}
                src={images.downArrow}
                alt="downArrow"
              ></img>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Journey;
// class History extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       activeID: 0,
//       wrapperStyle: {
//         backgroundImage: `url('${this.props.data[0].img}')`,
//       },
//       panelStyle: {
//         background: this.props.data[0].colour,
//       },
//       buttonHover: false,
//       buttonStyle: {
//         color: "#ffffff",
//       },
//     };
//   }
//   _changeActive(id) {
//     this.setState({
//       activeID: id,
//       wrapperStyle: {
//         backgroundImage: `url('${this.props.data[id].img}')`,
//       },
//       panelStyle: {
//         backgroundColor: this.props.data[id].colour,
//       },
//     });
//   }
//   _buttonColour() {
//     if (!this.state.buttonHover) {
//       this.setState({
//         buttonHover: true,
//         buttonStyle: {
//           color: this.props.data[this.state.activeID].colour,
//         },
//       });
//     } else {
//       this.setState({
//         buttonHover: false,
//         buttonStyle: {
//           color: "#ffffff",
//         },
//       });
//     }
//   }
//   render() {
//     return (
//       <section className="wrapper" style={this.state.wrapperStyle}>
//         <Selectors
//           data={this.props.data}
//           activeID={this.state.activeID}
//           _changeActive={this._changeActive.bind(this)}
//         />
//         <Panel
//           data={this.props.data[this.state.activeID]}
//           panelStyle={this.state.panelStyle}
//           buttonStyle={this.state.buttonStyle}
//           _buttonColour={this._buttonColour.bind(this)}
//         />
//       </section>
//     );
//   }
// }
// class Panel extends React.Component {
//   render() {
//     return (
//       <aside className="panel" style={this.props.panelStyle}>
//         <h2 className="panel-header">{this.props.data.header}</h2>
//         <p className="panel-info">{this.props.data.body}</p>
//         <button
//           className="panel-button"
//           style={this.props.buttonStyle}
//           onMouseEnter={this.props._buttonColour}
//           onMouseLeave={this.props._buttonColour}
//         >
//           Read More
//         </button>
//       </aside>
//     );
//   }
// }
// class Selectors extends React.Component {
//   _handleClick(e) {
//     if (this.props.id !== this.props.activeID) {
//       this.props._changeActive(this.props.id);
//     } else {
//       return;
//     }
//   }
//   render() {
//     return (
//       <div className="selectors">
//         {this.props.data.map((item) => (
//           <Selector
//             key={item.id}
//             id={item.id}
//             _handleClick={this._handleClick}
//             _changeActive={this.props._changeActive}
//             activeID={this.props.activeID}
//           />
//         ))}
//       </div>
//     );
//   }
// }
// class Selector extends React.Component {
//   render() {
//     let componentClass = "selector";
//     if (this.props.activeID === this.props.id) {
//       componentClass = "selector active";
//     }
//     return (
//       <div
//         className={componentClass}
//         onClick={this.props._handleClick.bind(this)}
//       ></div>
//     );
//   }
// }
