import React from "react";
import Fade from "react-awesome-reveal";
import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
  <div className="app__aboutus section__padding" id="about">
    <Fade bottom>
      <h1 className="headtext__cormorant">About Us</h1>
    </Fade>
    <div className="app__wrapper app__aboutus__inner">
      <div id="aboutUs__image" className=" aboutUs__image app__wrapper_img">
        <Fade bottom>
          <img
            className="synchro__frame_1"
            src={images.home}
            alt="header_img"
          />
        </Fade>
      </div>
      <div className="app__wrapper_info fixing">
        <div className="app__aboutus-content_about">
          <Fade bottom>
            <p className="p__opensans" id="aboutus__p">
              With the announcement of Antaragni ’24, the buzz for the biggest
              and most astounding rock fiesta is taking rounds, and honestly,
              none of us can keep calm! Welcome to Synchronicity, the haven for
              all rock music lovers in search of groovy music and amazing vibes!
              Where the country’s top bands put their best foot forward to
              compete and set the stage on fire with mind-blowing performances
              that electrify the environment. Over the years, Synchronicity has
              acted as a launchpad for some of India’s biggest rock acts
              including Parikrama, Them Clones, Pentagram, Blakc, The Family
              Cheese, Zero Gravity, and The Urban Earlymen. The event starts off
              with regional preliminary rounds- The ROADTRIPS, held last year in
              9 major cities, including two international ones in Kathmandu and
              Dhaka witnessing registration from more than 800 bands. Given the
              current situation, we are adding a twist to the procedure and
              conducting the first six roadtrips in a whole new online mode. One
              band moves on from each zone to compete in the semi-finals held at
              IIT Kanpur. In the end, only the top 3 make it to the Grand
              Finale. The grand finale is judged by the members of the
              International headliner and is cheered by strong headbanging crowd
              of over 21,000 people! The event closes with a special performance
              by the international headliner. Synchronicity'19 was headlined by
              Skrat with a scintillating performance by the Heavy Rock band from
              Chennai.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  </div>
  // <div className="app__wrapper app__aboutus-outer" id="about">
  //   <div className="app__aboutus flex__center">
  //     <div className="app__aboutus-overlay"></div>
  //     <div className="app__aboutus-content flex__center">
  //       <div className="app__aboutus-content_about">
  //         <Fade bottom>
  //           <h1 className="headtext__cormorant">About Us</h1>
  //         </Fade>
  //         <Fade bottom>
  //           <p className="p__opensans" id="aboutus__p">
  //             With the announcement of Antaragni ’22, the buzz for the biggest
  //             and most astounding rock fiesta is taking rounds, and honestly,
  //             none of us can keep calm! Welcome to Synchronicity, the haven for
  //             all rock music lovers in search of groovy music and amazing vibes!
  //             Where the country’s top bands put their best foot forward to
  //             compete and set the stage on fire with mind-blowing performances
  //             that electrify the environment. Over the years, Synchronicity has
  //             acted as a launchpad for some of India’s biggest rock acts
  //             including Parikrama, Them Clones, Pentagram, Blakc, The Family
  //             Cheese, Zero Gravity, and The Urban Earlymen. The event starts off
  //             with regional preliminary rounds- The ROADTRIPS, held last year in
  //             9 major cities, including two international ones in Kathmandu and
  //             Dhaka witnessing registration from more than 800 bands. Given the
  //             current situation, we are adding a twist to the procedure and
  //             conducting the first six roadtrips in a whole new online mode. One
  //             band moves on from each zone to compete in the semi-finals held at
  //             IIT Kanpur. In the end, only the top 3 make it to the Grand
  //             Finale. The grand finale is judged by the members of the
  //             International headliner and is cheered by strong headbanging crowd
  //             of over 21,000 people! The event closes with a special performance
  //             by the international headliner. Synchronicity'19 was headlined by
  //             Skrat with a scintillating performance by the Heavy Rock band from
  //             Chennai.
  //           </p>
  //         </Fade>
  //       </div>
  //     </div>
  //   </div>
  // </div>
);

export default AboutUs;
