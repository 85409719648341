import React from "react";
import Fade from "react-awesome-reveal";
import "./Guidelines.css";

const Guidelines = () => (
  <div className="app__guide flex__center" id="guidelines">
    <div className="app__guide-overlay"></div>

    <div className="app__guide-content flex__center">
      <div className="app__guide-content_guidelines">
        <Fade bottom>
          <h1 className="headtext__cormorant">Guidelines</h1>
          <p className="p__opensans" id="guide__p">
            ⚪ To register your band for the prelims fill in the details of the
            band.
          </p>
          <p className="p__opensans" id="guide__p">
            ⚪ In the city section fill in the city where you want to register
            for prelims.
          </p>
          <p className="p__opensans" id="guide__p">
            ⚪ You need to upload 2 of your Original Compoitions (OCs) on
            Dropbox/Google Drive and fill in the link in the mentioned section.
            Make sure the link can be viewed by anyone who has it.
          </p>
          <p className="p__opensans" id="guide__p">
            ⚪ You can also send us links of your songs on Reverbnation instead
            of Dropbox/Google Drive.
          </p>
          <p className="p__opensans" id="guide__p">
            ⚪ Please upload the best quality possible as these songs will be
            the short listing criteria for Prelims.
          </p>
          <p className="p__opensans" id="guide__p">
            ⚪ Use of any language in OCs is allowed.
          </p>
          <p className="p__opensans" id="guide__p">
            ⚪ You can also put the additional links such as your youtube links
            in the Other link section. If you have multiple such links, send all
            links separated by a comma.
          </p>
        </Fade>
      </div>
    </div>
  </div>
);

export default Guidelines;
