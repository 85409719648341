import React from "react";
import {
  AboutUs,
  Gallery,
  Header,
  Schedule,
  Contact,
  Sponsors,
  Register,
  Journey,
} from "./container";
import { Navbar } from "./components";
import "./css/App.css";
import { useState, useEffect } from "react";
import { getSingleDoc } from "./config/firebaseFirestore";
const App = () => {
  const [loading, setLoading] = useState(true);
  const [sponsors, setSponsors] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [contacts, setContacts] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    const rawData = await getSingleDoc("WebContents", "synchro");
    let contacts = [];
    let schedule = [];
    let sponsors = [];
    for (let i = 0; i < rawData.data.length; i++) {
      switch (rawData.data[i].flag) {
        case "contact":
          contacts.push(rawData.data[i]);
          break;
        case "sponsor":
          sponsors.push(rawData.data[i]);
          break;
        case "schedule":
          schedule.push(rawData.data[i]);
          break;
      }
    }
    setContacts(contacts);
    setSchedule(schedule);
    setSponsors(sponsors);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Navbar />
      <div className="app__bg">
        <Header />
        <AboutUs />
        <Journey />
        <Schedule data={schedule} />
        <Gallery />
        <Sponsors data={sponsors} />
        <Register />
        <Contact data={contacts} />
      </div>
    </div>
  );
};

export default App;
