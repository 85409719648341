import images from "./images";

const schedule = [
  {
    id: 1,
    img: images.kolkata,
    city: "Kolkata",
    date: "Sunday, Jul 31",
    result: "Panic",
  },
  {
    id: 2,
    img: images.shillong,
    city: "Shillong",
    date: "Sunday, Aug 28",
    result: "Necroscope",
  },
  {
    id: 3,
    img: images.imphal,
    city: "Imphal",
    date: "Saturday, Feb 4",
    result: "TBA",
  },
  {
    id: 4,
    img: images.bangalore,
    city: "Bangalore",
    date: "Sunday, Feb 5",
    result: "TBA",
  },
  {
    id: 5,
    img: images.mumbai,
    city: "Mumbai",
    date: "Saturday, Feb 11",
    result: "TBA",
  },
  {
    id: 6,
    img: images.hyderabad,
    city: "Hyderabad",
    date: "Sunday, Feb 12",
    result: "TBA",
  },
  {
    id: 7,
    img: images.delhi,
    city: "Delhi",
    date: "To be announced",
    result: "TBA",
  },
];

const journey = [
  {
    id: 1,
    year: 1965,
    img: images.gallery01,
    content:
      "Antaragni started in the year 1965 under the name of Culfest and was renamed to Antaragni in the year 1993.",
  },
  {
    id: 2,
    year: 1988,
    img: images.gallery02,
    content:
      "The now legendary rock metal band competition began in Antaragni under the name Synchronicity.",
  },
  {
    id: 3,
    year: 2007,
    img: images.gallery03,
    content:
      "Poets of the Fall headlined  the Rock Night (now Synchronicity night)",
  },
  {
    id: 4,
    year: 2010,
    img: images.gallery04,
    content: "Parikrama headlined Synchronicity",
  },
  {
    id: 5,
    year: 2014,
    img: images.gallery05,
    content: "First EDM Night at Antaragni",
  },
  {
    id: 6,
    year: 2015,
    img: images.gallery06,
    content: "50 Years of Antaragni’s legacy",
  },
  {
    id: 7,
    year: 2017,
    img: images.gallery07,
    content:
      "KSHMR (DJMAG rank 12) invited as the headliner for EDM Night in his first ever college festival performance",
  },
  {
    id: 8,
    year: 2018,
    img: images.journey2018,
    content:
      "QUINTINO (DJMAG rank 25) invited as the headliner for EDM Night in his first ever college festival performance",
  },
  {
    id: 9,
    year: 2021,
    img: images.home,
    content:
      "RITVIZ (King of Indie Pop) invited as the headliner for EDM Night in his  first ever college festival performance in Red Bull Bus Tour",
  },
];

const gallery = [
  {
    id: 1,
    img: images.gallery01,
  },
  {
    id: 2,
    img: images.gallery02,
  },
  {
    id: 3,
    img: images.gallery04,
  },
  {
    id: 4,
    img: images.gallery05,
  },
  {
    id: 5,
    img: images.gallery06,
  },
  {
    id: 6,
    img: images.gallery07,
  },
  {
    id: 7,
    img: images.gallery08,
  },
];

const contactInfo = [
  {
    id: 1,
    name: "Yash Manihar",
    post: "Organizer, Synchronicity",
    image:
      "https://drive.google.com/uc?export=view&id=1TJR-ZA58vX0OYx8MYDYbR5XRmAkDpD34",
    facebook: "/",
    phone: "6376266566",
    email: "synchronicity@antaragni.in",
    linkedin: "/",
  },
];

const sponsorData = [
  {
    id: 1,
    name: "Facebroke",
    link: "https://www.facebook.com/facebrokein/",
    img: images.facebroke,
    post: "Judges and headline band",
  },
  {
    id: 2,
    name: "The Indian Music Diaries",
    link: "https://www.instagram.com/theindianmusicdiaries/",
    img: images.TIMD,
    post: "Media and Publicity Partner",
  },
  {
    id: 3,
    name: "TopCat CCU",
    link: "https://www.facebook.com/topcatcculive/",
    img: images.topcat,
    post: "Venue Partner, Kolkata",
  },
  {
    id: 4,
    name: "KINGSTUDIO",
    link: "https://www.thekingstudio.com/",
    img: images.kingstudio,
    post: "Recording Partner, Kolkata",
  },
  {
    id: 5,
    name: "North East Now",
    link: "https://instagram.com/northeast.now?igshid=NDk5N2NlZjQ=",
    img: images.northEasternNews,
    post: "Media and Publicity Partner, Imphal",
  },
  {
    id: 6,
    name: "The North Eastern Chronicle",
    link: "https://instagram.com/north.eastern.chronicle?igshid=NDk5N2NlZjQ=",
    img: images.TNEC,
    post: "Media and Publicity Partner, Imphal",
  },
  {
    id: 7,
    name: "The Ace",
    link: "https://instagram.com/the_ace_imf?igshid=NDk5N2NlZjQ=",
    img: images.ace,
    post: "Venue Partner, Imphal",
  },
  {
    id: 8,
    name: "The Black Connection Talents",
    link: "https://www.instagram.com/the_blackconnection_talents/",
    img: images.blckcnn,
    post: "Judges Partner, Imphal",
  },
  {
    id: 9,
    name: "Darkville Studios",
    link: "https://www.instagram.com/darkvillestudio/",
    img: images.darkVille,
    post: "Recording Partner, Imphal",
  },
  {
    id: 10,
    name: "Samuel Haokip Photography",
    link: "https://www.instagram.com/samuel_haokip_photography/",
    img: images.SHP,
    post: "Coverage Partner, Imphal",
  },
];

const pastSponsors = [
  {
    id: 1,
    name: "Chennai Night Life",
    img: images.cnl,
    link: "https://www.facebook.com/chennainightlife.in/",
    post: "Media Partner, Chennai",
  },
  {
    id: 2,
    name: " Raag to Rock",
    img: images.rtr,
    link: "https://www.facebook.com/RagatoRock/",
    post: " Media Partner, Kolkata",
  },
];

const data = {
  schedule,
  gallery,
  contactInfo,
  sponsorData,
  pastSponsors,
  journey,
};

export default data;
