import { Fade } from "react-awesome-reveal";
import { useState, useEffect } from "react";
import { data } from "../../constants";
import "./Form.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getDoc, doc, getFirestore } from "firebase/firestore";
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCAZ2A8ujN30ZJx1cxCCPJ24DH9MUXMScs",
  authDomain: "dev-antaragni.firebaseapp.com",
  projectId: "dev-antaragni",
  storageBucket: "dev-antaragni.appspot.com",
  messagingSenderId: "928012944720",
  appId: "1:928012944720:web:9e8b21cd732247076a5855",
});

const firestore = firebase.firestore(firebaseApp);
const db = firestore.collection("synchronicity24");

const Form = () => {
  const [member1, setMember1] = useState("");
  const [member2, setMember2] = useState("");
  const [member3, setMember3] = useState("");
  const [member4, setMember4] = useState("");
  const [member5, setMember5] = useState("");
  const [member6, setMember6] = useState("");
  const [bandName, setBandName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [cityName, setCityName] = useState("");
  const [eventName, setEventName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [songLink, setSongLink] = useState("");
  const [moreSongs, setMoreSongs] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [otherProfiles, setOtherProfiles] = useState("");

  const [formSubmitted, setSubmitted] = useState(false);

  const [cities, setCities] = useState([]);
  const fetchData = async () => {
    try {
      const citiesDB = getFirestore(firebaseApp);
      const docRef = doc(citiesDB, "WebContents", "roadtripsCities");
      const docSnap = await getDoc(docRef);
      const rawData = docSnap.data();
      let cities = [];
      for (let i = 0; i < rawData.data.length; i++) {
        if (rawData.data[i].flag === "synchro") {
          cities.push(rawData.data[i].city);
        }
      }
      setCities(cities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "member1") {
      setMember1(value);
    }
    if (id === "member2") {
      setMember2(value);
    }
    if (id === "member3") setMember3(value);
    if (id === "member4") setMember4(value);
    if (id === "member5") setMember5(value);
    if (id === "member6") setMember6(value);
    if (id === "bandName") {
      setBandName(value);
    }
    if (id === "emailId") {
      setEmailId(value);
    }
    if (id === "cityName") {
      setCityName(value);
    }
    if (id === "eventName") {
      setEventName(value);
    }
    if (id === "contactNumber") {
      setContactNumber(value);
    }
    if (id === "alternateNumber") setAlternateNumber(value);
    if (id === "songLink") {
      setSongLink(value);
    }
    if (id === "moreSongs") setMoreSongs(value);
    if (id === "facebookLink") {
      setFacebookLink(value);
    }
    if (id === "otherProfiles") setOtherProfiles(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      name: bandName.toUpperCase(),
      email: emailId,
      city: cityName.toUpperCase(),
      event: eventName.toUpperCase(),
      member1: member1.toUpperCase(),
      member2: member2.toUpperCase(),
      member3: member3.toUpperCase(),
      member4: member4.toUpperCase(),
      member5: member5.toUpperCase(),
      member6: member6.toUpperCase(),
      phone: contactNumber,
      altphone: alternateNumber,
      songs: songLink,
      moresongs: moreSongs,
      fblink: facebookLink,
      otherlinks: otherProfiles,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    db.doc()
      .set(obj)
      .then(() => {})
      .catch((error) => {
        console.log(error);
        alert("OH No! Error occured while Form Submission");
      });
    setSubmitted(true);
  };

  if (formSubmitted) {
    return (
      <div className="app__form app__submitted">
        <Fade cascade bottom>
          <h1 className="h1__submitted">Form Submitted</h1>
        </Fade>
      </div>
    );
  }
  return (
    <div className="app__form">
      <Fade cascade bottom>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="row row-cols-sm-2 row-cols-1">
            {/* BandName */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="bandName"
                placeholder="bandName"
                value={bandName}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="bandName">Band Name *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Email Id */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="email"
                className="form__input form-control"
                id="emailId"
                placeholder="emailId"
                value={emailId}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="emailId">Email ID *</label>
              <div className="invalid-feedback">Invalid Email Id provided</div>
            </div>

            {/* City Name */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="cityName"
                placeholder="cityName"
                value={cityName}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="cityName">City Name *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Event Name */}
            <div className="form__column form-floating mb-3">
              <select
                className="form-select"
                id="eventName"
                placeholder="eventName"
                value={eventName}
                onChange={(e) => handleInputChange(e)}
                required
              >
                <option value="--SELECT--" selected>
                  --SELECT--
                </option>
                {cities.map((city) => (
                  <option value={city}>{city}</option>
                ))}
                <option value="All India Prelims">All India Prelims</option>
              </select>
              <label for="floatingSelect">Event Name *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>
          </div>

          <div className="row row-cols-md-3 row-cols-1 row-cols-sm-2 ">
            {/* Member 1 */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member1"
                placeholder="member1"
                value={member1}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="member1">Member 1 *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Member 2 */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member2"
                placeholder="member2"
                value={member2}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="member2">Member 2 *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Member 3 */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member3"
                placeholder="member3"
                value={member3}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="member3">Member 3</label>
            </div>

            {/* Member 4 */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member4"
                placeholder="member4"
                value={member4}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="member4">Member 4</label>
            </div>

            {/* Member 5 */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member5"
                placeholder="member5"
                value={member5}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="member5">Member 5</label>
            </div>

            {/* Member 6 */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member6"
                placeholder="member6"
                value={member6}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="member6">Member 6</label>
            </div>
          </div>

          <p
            style={{ fontWeight: "700", color: "var(--color-golden)" }}
            className="p__opensans"
          >
            ** If there are more than 6 members, write comma separated names **
          </p>

          <div className="row row-cols-sm-2 row-cols-1">
            {/* Contact Number */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="number"
                className="form__input form-control"
                id="contactNumber"
                placeholder="contactNumber"
                value={contactNumber}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="contactNumber">Contact Number*</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Alternate Number */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="number"
                className="form__input form-control"
                id="alternateNumber"
                placeholder="alternateNumber"
                value={alternateNumber}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="alternateNumber">Alternate Number</label>
            </div>

            {/* Song Link */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="url"
                className="form__input form-control"
                id="songLink"
                placeholder="songLink"
                value={songLink}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="songLink">Song Link*</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* More Song Links */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="moreSongs"
                placeholder="moreSongs"
                value={moreSongs}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="moreSongs">More Song Links</label>
            </div>

            {/* FaceBook Link */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="url"
                className="form__input form-control"
                id="facebookLink"
                placeholder="facebookLink"
                value={facebookLink}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="facebookLink">Facebook Profile Link*</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Other Profile Links */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="otherProfiles"
                placeholder="otherProfiles"
                value={otherProfiles}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="otherProfiles">Other Profile Links</label>
            </div>
          </div>

          <button className="btn btn-light" type="submit">
            Submit
          </button>
        </form>
      </Fade>
    </div>
  );
};

export default Form;
