import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className={colorChange ? "app__navbar colorChange" : "app__navbar"}>
      <div className="app__navbar-logo">
        <img src={images.synchro_logo} alt="synchronicity_logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home">Home</a>
        </li>
        <li className="p__opensans">
          <a href="#about">About</a>
        </li>
        <li className="p__opensans">
          <a href="#journey">Journey</a>
        </li>
        <li className="p__opensans">
          <a href="#schedule">Schedule</a>
        </li>
        <li className="p__opensans">
          <a href="#gallery">Gallery</a>
        </li>
        <li className="p__opensans">
          <a href="#sponsors">Sponsors</a>
        </li>
        <li className="p__opensans">
          <a href="#register">Register</a>
        </li>
        <li className="p__opensans">
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="app__navbar-login">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/synchronicity.antaragni/"
          className="p__opensans"
        >
          <img className="navbar__social" src={images.insta} alt="instagram" />
        </a>
        <div />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/synchronicity.iitk"
          className="p__opensans"
        >
          <img className="navbar__social" src={images.fb} alt="facebook" />
        </a>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <button
              content="\u00d7"
              fontSize={27}
              className="navbar__close_btn"
              onClick={() => setToggleMenu(false)}
            >
              Close
            </button>
            <ul className="app__navbar-smallscreen_links">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/synchronicity.antaragni/"
                  className="p__opensans"
                >
                  <img
                    className="navbar__social"
                    src={images.insta}
                    alt="instagram"
                  />
                </a>
                <h1 style={{ display: "inline" }}>|</h1>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/synchronicity.iitk"
                  className="p__opensans"
                >
                  <img
                    className="navbar__social"
                    src={images.fb}
                    alt="facebook"
                  />
                </a>
              </li>
              <li>
                <a href="#home" onClick={() => setToggleMenu(false)}>
                  Home
                </a>
              </li>
              <li>
                <a href="#about" onClick={() => setToggleMenu(false)}>
                  About
                </a>
              </li>
              <li>
                <a href="#journey" onClick={() => setToggleMenu(false)}>
                  Journey
                </a>
              </li>
              <li>
                <a href="#schedule" onClick={() => setToggleMenu(false)}>
                  Schedule
                </a>
              </li>
              <li>
                <a href="#gallery" onClick={() => setToggleMenu(false)}>
                  Gallery
                </a>
              </li>
              <li>
                <a href="#sponsors" onClick={() => setToggleMenu(false)}>
                  Sponsors
                </a>
              </li>
              <li>
                <a href="#register" onClick={() => setToggleMenu(false)}>
                  Register
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => setToggleMenu(false)}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
