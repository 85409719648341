import { Fade } from "react-awesome-reveal";
import "./Sponsors.css";
import "../../constants/styles.css";
import { FaInstagram } from "react-icons/fa";
import { FaChrome } from "react-icons/fa";

const Sponsors = ({ data }) => {
  return (
    <div className="app__wrapper" id="sponsors">
      <div className="app__sponsors  section__padding ">
        <Fade bottom>
          <h1 className="headtext__cormorant h1_sponsors">Sponsors</h1>
        </Fade>
        <Fade bottom cascade>
          {data.length <= 0 ? (
            <h1 style={{ color: "white" }}>To Be Announced...</h1>
          ) : (
            <div className="sponsors-container">
              {data.map((sponsor) => {
                return (
                  <div className="sponsors-card">
                    <img
                      className="sponsors-img"
                      src={sponsor.img.url}
                      alt={sponsor.name}
                    />
                    <div className="textBox">
                      <div className="iconsContainer">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={sponsor.instagram}
                        >
                          <FaInstagram
                            color={"var(--icon-color-one)"}
                            className="insta"
                            size="30"
                          />
                        </a>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={sponsor.website}
                        >
                          <FaChrome
                            color={"var(--icon-color-two)"}
                            className="chrome"
                            size="30"
                          />
                        </a>
                      </div>
                    </div>
                    {/* <p className="p__cormorant">{sponsor.text2}</p>
                    <div className="iconsContainer">
                    <a target="_blank" rel="noreferrer" href={sponsor.text1}>
                    <FaInstagram color={"var(--color-white)"} className="insta"/>
                    </a>
                    <a target="_blank" rel="noreferrer" href={sponsor.text1}>
                      <FaChrome color={"var(--color-white)"} className="chrome"/>
                    </a>
                  </div> */}
                  </div>
                );
              })}
            </div>
          )}
        </Fade>
        {/* <Fade bottom>
          <h1 className="headtext__cormorant h1_sponsors">Past Sponsors</h1>
        </Fade>
        <Fade bottom cascade>
          <div className="row align-content-center justify-content-center row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row_sponsors">
            {pastSponsorsData.map((sponsor) => {
              return (
                <div key={sponsor.Id} className="col imageDiv_sponsors">
                  <a target="_blank" rel="noreferrer" href={sponsor.link}>
                    <img
                      className="img_sponsors"
                      src={sponsor.img.url}
                      alt={sponsor.name}
                    />
                  </a>
                  <p className="p__cormorant">{sponsor.post}</p>
                </div>
              );
            })}
          </div>
        </Fade> */}
      </div>
    </div>
  );
};

export default Sponsors;
