import { useState, useEffect, useRef } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Fade } from "react-awesome-reveal";
import { data } from "../../constants";
import "./Gallery.css";
const Gallery = () => {
  const scrollRef = useRef(null);

  const scrollLeftDir = () => scroll("left");
  const scrollRightDir = () => scroll("right");

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (current && current.scrollLeft != null) {
      if (direction === "left") {
        current.scrollLeft -= 1000;
      } else if (direction === "right") {
        current.scrollLeft += 800;
      }
    }
  };

  return (
    <div className="app__gallery" id="gallery">
      <div className="app__gallery-content">
        <Fade bottom>
          <h1 className="headtext__cormorant">Photo Gallery</h1>
        </Fade>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {data.gallery.map((image, index) => (
            <div
              className="app__gallery-images_card flex__center"
              key={`gallery_image-${index + 1}`}
            >
              <img
                className="gallery__image"
                src={image.img}
                alt="gallery_image"
              />
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <div className="gallery__arrows-div">
            <BsArrowLeftShort
              className="gallery__arrow-icon"
              onClick={scrollLeftDir}
            />
          </div>
          <div className="gallery__arrows-div">
            <BsArrowRightShort
              className="gallery__arrow-icon"
              onClick={scrollRightDir}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
